import React from "react"
import SEO from "../components/seo"
import Chapter1 from "src/views/Chapter1"
import OGImage from "../images/seo/poczatek_radia.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Historia polskiej radiofonii"
        description="Jak rodziło się radio w Polsce? Jak funkcjonowało Polskie Radio przed wojną?"
        image={OGImage}
      />
      <Chapter1 />
    </>
  )
}

export default IndexPage
