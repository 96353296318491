import React, { useRef } from "react"
import { useArticles } from "src/hooks"
import { Iterator } from "src/util"
import TitleAndParagraph from "src/components/TitleAndParagraph"
import ArticleRows from "src/components/ArticleRows"
import Subtitle from "src/components/Subtitle"
import NextChapterLink from "src/components/NextChapterLink"
import BodyWrap from "src/components/BodyWrap"
import { AnimatePresence, motion } from "framer-motion"
import StationMap from "src/components/StationMap"
import H1 from "src/components/H1"

const Chapter1 = () => {
  const articles = useArticles({ articleCategory: 8954 })
  const iterator = useRef()
  const pointPositions = [
    { left: 37, bottom: 27.5, city: "kraków" },
    { left: 10, bottom: 55, city: "poznań" },
    { left: 23.5, bottom: 28.5, city: "katowice" },
    { left: 75, bottom: 85, city: "wilno" },
    { left: 61, bottom: 28, city: "lwów" },
    { left: 25, bottom: 44, city: "łódź" },
    { left: 17, bottom: 64, city: "toruń" },
    { left: 36, bottom: 53.5, city: "warszawa ii" },
    { left: 74.5, bottom: 62, city: "baranowicze" },
    { left: 31, bottom: 51, city: "raszyn" },
    { left: 65.5, bottom: 43, city: "łuck" },
  ]
  const MapPointArticles = articles.list.slice(10, 21)
  return (
    <AnimatePresence exitBeforeEnter>
      {articles.list?.length > 0 &&
        (iterator.current = new Iterator({ array: articles.list })) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BodyWrap>
              <H1 />
              <TitleAndParagraph article={iterator.current.current()} />
              <Subtitle content={iterator.current.next().Title} />

              <ArticleRows
                articles={iterator.current.next(3)}
                variant={"medium"}
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <Subtitle content={iterator.current.next().Title} />

              <ArticleRows
                articles={iterator.current.next(3)}
                variant={"medium"}
              />
              <StationMap
                articles={[articles.list[22], ...MapPointArticles]}
                pointPositions={pointPositions}
              />
            </BodyWrap>
            <NextChapterLink
              title="Historia ma głos"
              href="historia-ma-glos"
              style={{ marginTop: "4rem" }}
            />
          </motion.div>
        )}
    </AnimatePresence>
  )
}

export default Chapter1
