import styled, { keyframes } from "styled-components"
import map from "src/images/radioMap.png"
import redPoint from "src/images/mapRedPoint.svg"
import blackPoint from "src/images/mapBlackPoint.svg"

const mapPointAnimation = keyframes`
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`

export const MapTitle = styled.div`
  display: flex;
  p {
    color: #fdfdfd;
    font-family: "Abril Fatface";
    font-size: 3.65rem;
    background-color: #020710;
    padding: 0 1rem 0.6rem 0;
  }
`

export const MapLead = styled.div`
  color: #fdfdfd;
  font-family: "Open Sans";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-top: 6rem;
`

export const MapContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const MapWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 3rem;
`

export const TextContainer = styled.div`
  max-width: 50%;
  padding-left: 7.7rem;
`

export const ExactMap = styled.div`
  width: 40.3rem;
  height: 45.77rem;
  min-width: 410px;
  min-height: calc(410px * 1.14);
  background-image: url(${map});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: relative;
`

export const MapPoint = styled.svg`
  width: 7%;
  height: 10%;
  background-image: url(${redPoint});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  left: ${({ leftPosition }) => leftPosition}%;
  bottom: ${({ bottomPosition }) => bottomPosition}%;
  cursor: pointer;
  animation: 1s ${mapPointAnimation} ease-out infinite;
  transition: 0.1s;
  &:hover {
    width: 11%;
    height: 15%;
    left: ${({ leftPosition }) => leftPosition - 2}%;
    background-image: url(${blackPoint});
  }
`

export const MapContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 4rem;
`

export const MapTitleMobile = styled.div`
  p {
    color: #fdfdfd;
    font-family: "Abril Fatface";
    font-size: 3.65rem;
    background-color: #020710;
    display: inline;
    padding: 0 1rem;
    padding-bottom: 0.6rem;
    box-decoration-break: clone;
    font-weight: 400;
    margin-left: -1rem;
    width: 100%;
  }
`

export const MapLeadMobile = styled.div`
  color: #fdfdfd;
  font-family: "Montserrat";
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6;
  margin: 2rem 0 4rem 0;
`

export const SingleText = styled.div`
  color: rgb(249, 55, 55);
  font-family: "Montserrat";
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  padding: 2rem 0;
  font-weight: 500;
  border-bottom: ${({ lastIndex }) => {
    return !lastIndex ? "1px solid rgba(146, 22, 22, 0.6)" : "none"
  }};
`
