import React from "react"
import { Wrapper, Content } from "./styles"

const Subtitle = ({ content }) => {
  return (
    <Wrapper>
      <Content tabindex="0">{content}</Content>
    </Wrapper>
  )
}

export default Subtitle
