import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  width: ${({ width }) => width || "100"}%;
  margin-bottom: 2rem;
`
export const Content = styled.h2`
  color: #fdfdfd;
  font-family: "Abril Fatface";
  font-size: 43px;
  font-weight: 400;

  @media (max-width: 1024px) {
    font-size: 2.3rem;
    line-height: 1.2;
  }
`
