import React, { useState } from "react"
import Modal from "src/components/Modal/Modal"
import {
  MapContainer,
  MapWrapper,
  TextContainer,
  ExactMap,
  MapPoint,
  MapTitle,
  MapLead,
  MapContainerMobile,
  MapTitleMobile,
  MapLeadMobile,
  SingleText,
} from "./styles"
import { Title, Text } from "src/components/ArticleRow/styles"
import { isMobile } from "react-device-detect"

const StationMap = ({ articles, pointPositions }) => {
  const [isOpen, setOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({})

  const mapRedPoints = articles.map((x, index) => {
    if (index !== 0) {
      return (
        <MapPoint
          onClick={() => openModal(articles[index])}
          image={pointPositions[index - 1].photo}
          leftPosition={pointPositions[index - 1].left}
          bottomPosition={pointPositions[index - 1].bottom}
          type={pointPositions[index - 1].type}
        />
      )
    }
  })

  const listOfCities = articles.map((x, index) => {
    if (index !== 0) {
      return (
        <SingleText
          onClick={() => openModal(articles[index])}
          image={pointPositions[index - 1].photo}
          leftPosition={pointPositions[index - 1].left}
          bottomPosition={pointPositions[index - 1].bottom}
          type={pointPositions[index - 1].type}
          lastIndex={index === articles.length - 1 ? true : false}
        >
          {pointPositions[index - 1].city}
        </SingleText>
      )
    }
  })

  const getAudio = attachments => {
    return attachments?.filter(attachment => attachment?.Audio)
  }

  const openModal = info => {
    setModalInfo(info)
    setOpen(true)
  }

  const closeModal = () => {
    setModalInfo({})
    setOpen(false)
  }

  const modal = () => {
    return (
      <Modal
        open={isOpen}
        photoSrc={modalInfo.Attachments ? modalInfo.Attachments[0].Photo : ""}
        handlerClose={closeModal}
        audioArray={getAudio(modalInfo?.Attachments)}
      >
        <Title
          style={{
            color: "#020417",
            display: "block",
            marginBottom: "3rem",
            textAlign: isMobile && "center",
            width: isMobile && "100%",
          }}
        >
          {modalInfo.Title}
        </Title>
        {modalInfo.Lead && modalInfo.Lead.length > 0 && (
          <Text style={{ color: "#020417", display: "block" }}>
            {modalInfo.Lead}
          </Text>
        )}
        <Text
          dangerouslySetInnerHTML={{ __html: modalInfo?.Content }}
          style={{
            color: "#020417",
            display: "block",
            marginBottom: "3rem",
          }}
        />
      </Modal>
    )
  }

  const desktopMap = () => {
    return (
      <>
        <MapContainer>
          <MapTitle>
            <p>{articles[0].Title}</p>
          </MapTitle>
          <MapWrapper>
            <ExactMap>{mapRedPoints}</ExactMap>
            <TextContainer>
              <MapLead>{articles[0].Lead}</MapLead>
            </TextContainer>
          </MapWrapper>
          {modal()}
        </MapContainer>
      </>
    )
  }

  const mobileMap = () => {
    return (
      <MapContainerMobile>
        <MapTitleMobile>
          <p>{articles[0].Title}</p>
        </MapTitleMobile>
        <MapLeadMobile>{articles[0].Lead}</MapLeadMobile>
        {listOfCities}
        {modal()}
      </MapContainerMobile>
    )
  }

  return (
    <>
      {!isMobile && desktopMap()}
      {isMobile && mobileMap()}
    </>
  )
}

export default StationMap
